:root {
    --slider-thumb-size: 20px;
    --slider-thumb-color: #0286b6;
    --slider-bg-color: #ddd;
    --slider-height: 6px;
    --slider-input-width: 10px;
  }
  
  @mixin slider-base {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: var(--slider-height);
    background: var(--slider-bg-color);
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
  
    &:hover {
      opacity: 1;
    }
  }
  
  @mixin slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--slider-thumb-size);
    height: var(--slider-thumb-size);
    background: var(--slider-thumb-color);
    border-radius: 50%;
    cursor: pointer;
  }
  
  @mixin slider-input {
    width: var(--slider-input-width);
    margin-left: 10px;
    padding: 5px;
    font-size: 16px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    -moz-appearance: textfield; /* Hide spinner in Firefox */
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* Hide spinner in Chrome, Safari, Edge, Opera */
    }
  }
  