@use '../../../styles/media-queries' as mq;
@use '../../../styles/slider' as slider;

.slider-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.slider {
  @include slider.slider-base;

  &::-webkit-slider-thumb {
    @include slider.slider-thumb;
  }

  &::-moz-range-thumb {
    @include slider.slider-thumb;
  }

  @include mq.large-handset {
    height: 8px;

    &::-webkit-slider-thumb {
      width: 18px;
      height: 18px;
    }

    &::-moz-range-thumb {
      width: 18px;
      height: 18px;
    }
  }
}

.slider-input {
  @include slider.slider-input;
}

.full-width {
  width: 100%;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.contrasted {
  .slider {
    background: var(--contrasted-slider-bg-color, #000);

    &::-webkit-slider-thumb {
      background: var(--contrasted-slider-thumb-color, #fff);
    }

    &::-moz-range-thumb {
      background: var(--contrasted-slider-thumb-color, #fff);
    }
  }
}
