@use '../../../styles/button' as btn;
@use '../../../styles/media-queries' as mq;

.btn {
  @include btn.button-base;
  gap: 14px;
  color: var(--btn-color, var(--white-700));
  padding: var(--btn-padding-y, 16px) var(--btn-padding-x, 32px);
  font-size: var(--btn-font-size, 14px);
  line-height: 13.54px;
  font-family: var(--primary-font-family);
  transition: all 240ms ease-in-out;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.08);
  border: 1px solid transparent;

  &:hover {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.15);
  }

  @include mq.large-handset {
    padding: var(--btn-padding-y, 15px) var(--btn-padding-x, 40px);
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.full-width {
  width: 100%;
}

.contained {
  color: var(--btn-contained-color, #edebe9);
  background: var(
    --btn-bg-color,
    linear-gradient(103.4deg, #0286b6 9.62%, #02bec5 88.45%)
  );
  background-clip: padding-box;
}

.outlined {
  background-color: transparent;
  @include btn.bordered-button;
}
.round {
  border-radius: 24px;
  font-size: 16px;
  line-height: 20px;
  box-shadow: 0px 3px 13px #1d0a4f14;
  background-color: var(--container-bg-color);
  color: var(--primary-color);
}

.contrasted {
  color: var(--contrasted-btn-color, #0286b6);
  background: var(--contrasted-btn-bg-color, var(--white-700));
}
.contrasted.contained {
  border: 1px solid#0286b6;
}
.gradient {
  color: #0286b6;
  background: transparent
    linear-gradient(91deg, var(--cyan-500) 0, #d82a90 100%) 0 0 no-repeat
    padding-box;
}
