.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.title {
  font-size: 40px;
  line-height: 30px;
  font-family: var(--primary-font-family);
  font-weight: 300;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .loadingContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px 0;
    justify-items: center;
    :global(.MuiCircularProgress-root) {
      color: var(--secondary-color);
    }
  }
}
.text,
.success,
.error {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--san-francisco-font);
  color: var(--secondary-color);
  font-weight: 300;
}
