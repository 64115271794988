@use 'sass:math';

@mixin mobile-small {
  $change-at: 325px;
  @media (min-width: #{$change-at}) {
    @content;
  }
}

@mixin large-handset {
  $change-at: 480px;
  @media (min-width: #{$change-at}) {
    @content;
  }
}

@mixin flexible-mobile {
  $change-at: 540px;
  @media (min-width: #{$change-at}) {
    @content;
  }
}

@mixin tablet-small {
  $change-at: 600px;
  @media (min-width: #{$change-at}) {
    @content;
  }
}

@mixin tablet {
  $change-at: 768px;
  @media (min-width: #{$change-at}) {
    @content;
  }
}

@mixin large-tablet {
  $change-at: 1024px;
  @media (min-width: #{$change-at}) {
    @content;
  }
}

@mixin medium-window {
  $change-at: 1280px;
  @media (min-width: #{$change-at}) {
    @content;
  }
}

@mixin large-window {
  $change-at: 1440px;
  @media (min-width: #{$change-at}) {
    @content;
  }
}

@mixin desktop-large {
  $change-at: 1920px;
  @media (min-width: #{$change-at}) {
    @content;
  }
}
