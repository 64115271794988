:global(.MuiDialog-paper) {
  border-radius: 22px;
  background: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(235, 233, 231, 1);
  box-shadow: 0px -4px 2px 0px rgba(0, 0, 0, 0.05) inset;
}
:global(.MuiBackdrop-root) {
  backdrop-filter: blur(5px);
}

.container {
  color: var(--primary-color);
  padding: 40px 12%;
}
