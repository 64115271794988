@use '../../../styles/media-queries' as mq; // Adjust the path to correctly reference _media-queries.scss


.container {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content auto;
  min-height: 100vh;
  background-image: url('../../../media/app-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}
.banner,
.content,
.header {
  position: relative;
  z-index: 5;
  padding: 30px 3% 0;
  @include mq.large-tablet {
    padding: 0 10% 0;
  }
}
.content {
  container-type: inline-size;
  display: flex;
  flex-direction: column;
}
.banner {
  background: linear-gradient(
    103.4deg,
    #0286b6 9.62%,
    #02bec5 88.45%,
    #02bec5 88.45%
  );
  font-size: 12px;
  line-height: 14px;
  font: var(--san-francisco-font);
  padding: 16px 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 80px;

  background: rgba(255, 255, 255, 0.8);
  .connectBtn {
    display: none;
  }
  .logo {
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: var(--primary-font-family);
    font-size: 26px;
    font-weight: 600;
    line-height: 25.14px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #323130;
    cursor: pointer;
    img {
      width: auto;
      height: 42px;
      object-fit: contain;
    }
  }
  @include mq.tablet-small {
    .connectBtn {
      display: flex;
    }
  }
}

.content {
  align-items: center;
  justify-content: center;
  padding: 0 10%;
  @include mq.large-tablet {
    padding: 0 20%;
  }
  .card {
    padding: 40px 16%;
    border-radius: 22px;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(235, 233, 231, 1);
    box-shadow: 0px -4px 2px 0px rgba(0, 0, 0, 0.05) inset;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    color: var(--primary-color);
    text-align: center;
    @include mq.tablet {
      padding: 40px 22%;
    }
    .title {
      font-size: 40px;
      line-height: 30px;
      font-family: var(--primary-font-family);
      font-weight: 300;
    }
    .information {
      font-size: 16px;
      line-height: 24px;
      font-family: var(--san-francisco-font);
      color: var(--secondary-color);
      font-weight: 300;
    }
  }
  .amountContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    --btn-padding-x: 10px;
    --btn-padding-y: 5px;
    --btn-font-size: 12px;
    --btn-height: 30px;
    padding: 15px 20px 15px 24px;
    background: #ffffffb2;
    border-radius: 20px;
    gap: 12px;
    min-width: 60%;
  }
  .balanceContainer {
    font-family: var(--secondary-font-family);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    flex-grow: 1;
    input {
      border: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      background-color: transparent;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
    .balance {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #00000080;
    }
  }
  :global(.MuiStepLabel-root) {
    gap: 8px;
  }
}

.btn {
  --btn-padding-x: 20px;
  --btn-padding-y: 10px;
  --btn-border-radius: 80px;
}
